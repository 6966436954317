import { Disclosure } from '@headlessui/react'
import { Fragment } from 'react'
import React from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import { FormattedMessage } from 'react-intl'
import { BallPlinko, PlayingCardClover } from '@/icons/index'

export default function CasinoDisclosure({
  icon,
  className,
  active,
  centered,
  label,
  defaultLabel,
  prefetch = false,
  href = '#',
  page,
  imgHeight,
  imgWidth,
  header,
  bottom,
  registerClass,
  loginClass,
  exploreClass,
  home,
  ...rest
}) {
  const router = useRouter()
  return (
    <Disclosure as={Fragment}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`flex items-center space-x-2 text-white p-2 rounded-lg cursor-pointer  ${
              registerClass
                ? 'flex flex-row-reverse justify-center  gap-x-2 bg-[#008000] w-full sm:w-auto'
                : loginClass
                ? 'flex flex-row-reverse justify-center gap-x-2 bg-[#FFFFFF]/10  w-full sm:w-auto'
                : exploreClass
                ? 'flex justify-center mt-2 flex-row-reverse gap-x-2 bg-[#008000]'
                : ''
            }
            ${active ? `bg-[#fff]/10 ${className}` : 'hover:bg-[#fff]/10'} ${
              router.pathname.includes(page)
                ? `bg-[#fff]/10 ${className}`
                : 'hover:bg-[#fff]/10'
            } ${centered ? 'justify-center' : ''}`}
            {...rest}
          >
            {icon}
            <span
              className={`${
                registerClass || loginClass
                  ? 'text-lg'
                  : exploreClass
                  ? 'text-sm'
                  : 'text-base'
              }  font-medium font-inter whitespace-nowrap`}
            >
              <FormattedMessage id={label} defaultMessage={defaultLabel} />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className="text-sm bg-gray-800 rounded-md">
            <NextLink
              href={'/casino/plinko'}
              className={`${
                active ? `bg-[#fff]/10 ${className}` : 'hover:bg-[#fff]/10'
              } group flex w-full space-x-3 items-center rounded-md px-2 py-2 text-sm`}
            >
              <BallPlinko className="w-6 h-6 shrink-0" />
              <span
                className={`${
                  registerClass || loginClass
                    ? 'text-lg'
                    : exploreClass
                    ? 'text-sm'
                    : 'text-base'
                }  font-medium font-inter whitespace-nowrap`}
              >
                Plinko
              </span>
            </NextLink>
            <NextLink
              href={'/casino/video-poker'}
              className={`${
                active ? `bg-[#fff]/10 ${className}` : 'hover:bg-[#fff]/10'
              } group flex w-full space-x-3 items-center rounded-md px-2 py-2 text-sm`}
            >
              <PlayingCardClover className="w-6 h-5 shrink-0" />
              <span
                className={`${
                  registerClass || loginClass
                    ? 'text-lg'
                    : exploreClass
                    ? 'text-sm'
                    : 'text-base'
                }  font-medium font-inter whitespace-nowrap`}
              >
                Video Poker
              </span>
            </NextLink>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
